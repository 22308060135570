import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import './topDomains.css';

function TopDomains() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://internet-traffic-worker.abramojo02.workers.dev/popular-domains")
      .then((res) => res.json())
      .then((topDomainData) => {
        setData(topDomainData.rankingEntries);
      })
  }, [])
  
  if (!data) {
    return (<div>Loading...</div>);
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Rank</th>
          <th>Domain</th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((value, index) => {
            const change = value.rankChange < 0 ?
              <span className='red'> ({value.rankChange})</span> :
              <span className='green'> (+{value.rankChange})</span>
            const id = 'domain-' + index;
            let tooltipHtml = "<div>"
            tooltipHtml += "Rank: " + (Number(value.rank) + 1)
            tooltipHtml += "<br/>Domain: " + value.domain
            tooltipHtml += "<br/>Category: " + value.category
            tooltipHtml += "</div>"
            return (
              <tr key={index} id={id}>
                <td>{Number(value.rank) + 1}{value.rankChange === '0' ? '' : change}</td>
                <td>{value.domain}</td>
                <Tooltip anchorId={id} html={tooltipHtml} place="right" />
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default TopDomains;
