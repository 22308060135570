import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AttackTraffic() {
  const [data, setData] = useState(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Layer 3 Attack Traffic',
      },
    },
  };

  useEffect(() => {
    fetch("https://internet-traffic-worker.abramojo02.workers.dev/attack-layer3")
      .then((res) => res.json())
      .then((attackLayer3TrafficData) => {
        const labels = attackLayer3TrafficData.data.total.timestamps;
        setData(
          {
            labels,
            datasets: [
              {
                label: 'Total Traffic',
                data: attackLayer3TrafficData.data.total.values,
                borderColor: 'rgb(164, 36, 59)',
                backgroundColor: 'rgba(164, 36, 59, 0.5)',
              },
            ]
          }
        );
      })
  }, [])

  if (!data) {
    return (<div>Loading...</div>);
  }
  return (<Line data={data} options={options} />);
}

export default AttackTraffic;
