import './App.css';
import InternetTraffic from './internetTraffic';
import AttackTraffic from './attackTraffic';
import TopDomains from './topDomains';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function App() {
  const [displayedChart, setDisplayedChart] = useState(<InternetTraffic />);


  return (
    <div className="app">
      <div className="toggle-buttons">
        <button id="internet-traffic-button" className="toggle-button" onClick={()=>setDisplayedChart(<InternetTraffic />)}>
          HTTP Traffic
        </button>
        <Tooltip anchorId="internet-traffic-button" content="Comparison of Total Traffic and HTTP Traffic over the last 30 Days" place="right" />
        <button id="top-domains-button" className="toggle-button" onClick={()=>setDisplayedChart(<TopDomains />)}>
          Top Sites
        </button>
        <Tooltip anchorId="top-domains-button" content="Most Popular Domains over the last 30 Days" place="right" />
        <button id="attack-traffic-button" className="toggle-button" onClick={()=>setDisplayedChart(<AttackTraffic />)}>
          Attack Traffic
        </button>
        <Tooltip anchorId="attack-traffic-button" content="Layer 3 DDoS Attack Traffic over the last 30 Days" place="right" />
      </div>
      <div className="graph">
        {displayedChart}
      </div>
    </div>
  );
}

export default App;
